import React, { Component } from "react";
import Modal from "../components.js/Modal";
import BestScoreCard from "../components.js/BestScoreCard";

class RunnerGame extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creaturePosition: 0,
      creatureVerticalPosition: 0,
      obstaclePosition: 0,
      isOpen: false,
      gameOver: false,
      jumping: false,
      jumpHeight: 0, // Adjust max jump height as needed
      gravity: 5, // Adjust gravity as needed
      obstacleSpeed: 8, // Initial speed of the obstacles
      jumpStartTime: null, // Tracks when the jump started
      gameDuration: 0.001, // Track game duration in milliseconds
      topScores: [],
      pigSrc: "",
      isHorizontal: this.isDeviceHorizontal(),
    };
    this.jumpInterval = null; // Interval for simulating jump
    this.clockInterval = null;
    // Interval for updating the clock
  }
  startGame = () => {
    this.gameLoopInterval = setInterval(this.updateGame, 30);
    this.collisionCheckInterval = setInterval(this.checkCollision, 10);
    this.clockInterval = setInterval(this.updateClock, 1); // Update clock every millisecond
    document.addEventListener("keydown", this.handleKeyDown);
    document.addEventListener("keyup", this.handleKeyUp);

    // Retrieve top scores from local storage on component mount
    const storedScores = localStorage.getItem("topScoresRunner");
    if (storedScores) {
      this.setState({ topScores: JSON.parse(storedScores) });
    }
  };

  componentDidMount() {
    // this.startGame();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    clearInterval(this.gameLoopInterval);
    clearInterval(this.collisionCheckInterval);
    clearInterval(this.clockInterval); // Clear the clock interval
    document.removeEventListener("keydown", this.handleKeyDown);
    document.removeEventListener("keyup", this.handleKeyUp);
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    if (this.state.gameOver !== true)
      this.setState({
        isHorizontal: this.isDeviceHorizontal(),
      });
  };

  isDeviceHorizontal = () => {
    return window.matchMedia("(orientation: landscape)").matches;
  };
  updateClock = () => {
    const { gameOver, gameDuration } = this.state;
    if (!gameOver) {
      this.setState({ gameDuration: gameDuration + 1 }); // Increment game duration by 1 millisecond
    }
  };

  handleKeyDown = (event) => {
    if (event.keyCode === 32 && !this.state.jumping) {
      event.preventDefault(); // Prevent default action of space key
      this.handleJumpPress();
    }
  };

  handleKeyUp = (event) => {
    if (event.keyCode === 32) {
      this.handleJumpRelease();
    }
  };

  updateGame = () => {
    const { gameOver, jumping, creatureVerticalPosition, obstacleSpeed } =
      this.state;
    const containerWidth = window.innerWidth;

    if (!gameOver) {
      const newObstacleSpeed = obstacleSpeed + 0.01;

      const obstaclePosition = this.state.obstaclePosition - newObstacleSpeed;
      if (obstaclePosition < -50) {
        this.setState({
          obstaclePosition: containerWidth,
          obstacleSpeed: newObstacleSpeed,
        });
      } else {
        this.setState({ obstaclePosition, obstacleSpeed: newObstacleSpeed });
      }

      if (!jumping && creatureVerticalPosition > 0) {
        this.setState((prevState) => ({
          creatureVerticalPosition:
            prevState.creatureVerticalPosition - prevState.gravity,
        }));
      }
    }
  };

  checkCollision = () => {
    const {
      creaturePosition,
      obstaclePosition,
      jumping,
      creatureVerticalPosition,
      jumpHeight,
    } = this.state;
    const creatureWidth = 120;
    const creatureHeight = 120;
    const obstacleWidth = 120;
    const obstacleHeight = 120;

    const creatureBounds = {
      left: creaturePosition,
      right: creaturePosition + creatureWidth,
      top: creatureVerticalPosition,
      bottom: creatureVerticalPosition + creatureHeight,
    };
    const obstacleBounds = {
      left: obstaclePosition,
      right: obstaclePosition + obstacleWidth,
      top: 0,
      bottom: obstacleHeight,
    };

    if (
      creatureBounds.right + 218 >= obstacleBounds.left &&
      creatureBounds.left <= obstacleBounds.right - 218 &&
      creatureBounds.bottom >= obstacleBounds.top &&
      creatureBounds.top <= obstacleBounds.bottom &&
      !jumping &&
      creatureVerticalPosition <= jumpHeight
    ) {
      this.setState({ gameOver: true });

      // Save score to local storage
    }
  };

  handleJumpPress = () => {
    this.setState({ jumpStartTime: new Date().getTime() });
  };

  handleJumpRelease = () => {
    if (this.state.jumpStartTime) {
      const jumpDuration = new Date().getTime() - this.state.jumpStartTime;
      const jumpHeight = Math.min(jumpDuration * 0.1, 300);
      this.jump(jumpHeight);
      this.setState({ jumpStartTime: null });
    }
  };

  jump = (height) => {
    const { jumping, creatureVerticalPosition } = this.state;
    const minJumpHeight = 80;

    if (!jumping && creatureVerticalPosition === 0) {
      this.pauseGif();

      this.setState({ jumping: true, jumpHeight: height });

      this.jumpInterval = setInterval(() => {
        if (
          this.state.creatureVerticalPosition < height ||
          this.state.creatureVerticalPosition < minJumpHeight
        ) {
          this.setState((prevState) => ({
            creatureVerticalPosition: prevState.creatureVerticalPosition + 5,
          }));
        } else {
          clearInterval(this.jumpInterval);
          setTimeout(() => {
            this.resumeGif();
            this.setState({ jumping: false });
          }, 100);
        }
      }, 50);
    }
  };

  pauseGif = () => {
    if (this.gifImage) {
      this.gifImage.style.setProperty("animation-play-state", "paused");
    }
  };

  resumeGif = () => {
    if (this.gifImage) {
      this.gifImage.style.setProperty("animation-play-state", "running");
    }
  };

  restartGame = () => {
    this.setState({
      creaturePosition: 0,
      creatureVerticalPosition: 0,
      obstaclePosition: window.innerWidth,
      gameOver: false,
      obstacleSpeed: 8,
      gameDuration: 0,
    });
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.state.isHorizontal !== prevState.isHorizontal &&
      this.state.isHorizontal === true
    ) {
      setTimeout(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth", // Optional: adds smooth scrolling behavior
        });

        // Start the game 3 seconds after scrolling
        setTimeout(() => {
          this.startGame();
        }, 3000);
      }, 3000); // Wait 3 seconds before scrolling
    }
    if (
      this.state.gameOver !== prevState.gameOver &&
      this.state.gameOver === true
    ) {
      this.setState({ pigSrc: "YQDj.gif" });
      setTimeout(() => {
        this.setState({ pigSrc: "output-onlinegiftools.gif" });
      }, 1700);
      this.saveScore();
      this.setState({ isOpen: true });
    }
  };
  saveScore = () => {
    let topScores = JSON.parse(localStorage.getItem("topScoresRunner"))
      ? JSON.parse(localStorage.getItem("topScoresRunner"))
      : this.state.topScores;
    const { gameDuration } = this.state;
    const currentDate = new Date();
    const israeliTime = new Date(
      currentDate.getTime() +
        currentDate.getTimezoneOffset() * 60000 +
        180 * 60000
    ); // Adjusting for Israeli time zone offset

    const newScore = {
      //   date: ,
      time: `${israeliTime.toLocaleDateString()} ${israeliTime.getHours()}:${israeliTime.getMinutes()}`,
      score: gameDuration,
    };

    // Combine existing top scores and the new score
    const allScores = [...topScores, newScore];

    // Sort scores by duration in ascending order
    allScores.sort((a, b) => b.score - a.score);

    // Keep only top 5 scores
    const top5Scores = allScores.slice(0, 5);
    this.props.upadateTotalScore("fun", newScore.score / 100);

    // Update state and local storage
    this.setState({ topScores: top5Scores }, () => {
      localStorage.setItem("topScoresRunner", JSON.stringify(top5Scores));
    });
  };

  render() {
    const {
      creaturePosition,
      obstaclePosition,
      gameOver,
      creatureVerticalPosition,
      gameDuration,
      topScores,
    } = this.state;
    let pig =
      this.state.jumping === false
        ? "https://cdn.pixabay.com/animation/2022/08/16/14/11/14-11-30-461_512.gif"
        : "pig froze.png";

    if (this.state.gameOver) {
      pig = this.state.pigSrc; // Display "YQDj.gif" when game is over
      // Reset pig after 2.5 seconds
    }
    const { isHorizontal } = this.state;

    if (!isHorizontal) {
      return (
        <div className="orientation-message">
          Please rotate your device to landscape mode to play the game.
        </div>
      );
    } else {
      return (
        <div
          onTouchStart={this.handleJumpPress}
          onTouchEnd={this.handleJumpRelease}
          className="rungame-container"
          tabIndex="0"
        >
          <Modal isOpen={this.state.isOpen}>
            <BestScoreCard bestScoreCoins={topScores}></BestScoreCard>
          </Modal>
          <img
            className="rungame-creature"
            style={{ left: "218px", bottom: creatureVerticalPosition }}
            src={pig}
            alt="creature"
          />
          <div />

          <img
            className="runner"
            src="https://media2.giphy.com/media/v1.Y2lkPTc5MGI3NjExZDdobzBneWt0aXZ0YTYzbTRjZWlzcXloZGRsMjJ0bjJxNjM1bWVqeCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9cw/300WFSbiofkBXrYgn6/giphy.gif"
          ></img>
          <div>
            <img
              className="rungame-obstacle"
              style={{ left: obstaclePosition }}
              src="ezgif-7-7f57885133.gif"
              alt="obstacle"
            ></img>
          </div>
          {gameOver && <div className="rungame-game-over">Game Over!</div>}
          <div className="game-duration">Game Duration: {gameDuration} ms</div>
          <div className="top-scores">
            <h2>Top 5 Scores</h2>
            <ul>
              {/* {topScores.map((score, index) => (
              <li key={index}>
                <span>{score.date}</span> - <span>{score.hour}</span> -{" "}
                <span>{score.duration} ms</span>
              </li>
            ))} */}
            </ul>
          </div>
          {gameOver && <button onClick={this.restartGame}>Restart</button>}
        </div>
      );
    }
  }
}

export default RunnerGame;
