import React, { Component } from "react";

class ProgressBar extends Component {
  render() {
    const { number } = this.props;
    let largest = this.props.largest;
    const precentage = (largest - number) * (100 / largest);
    const progressWidth = `${100 - precentage}%`;
    const destination = this.props.destination;

    return (
      <div className="progress-bar-container">
        <div className="progress-bar" style={{ width: progressWidth }}>
          {/* {number}% */}
        </div>
      </div>
    );
  }
}

export default ProgressBar;
