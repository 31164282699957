import React, { Component } from "react";
import { coinImg, diamondImg, foods, playAudio } from "../components.js/utils";
import Counter from "../components.js/Counter";
import Bonus from "../components.js/Bonus";

import CreaturesManu from "../components.js/CreaturesManu";
import Modal from "../components.js/Modal";
import RunnerGame from "./RunnerGame";
import BalloonsPoppingGame from "./BalloonsPoppingGame";
import CountersBar from "../components.js/CountersBar";

class SlotMachine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bonus: 0,
      foodBonus: 0,
      "tiger.png": 0,
      "dog.png": 0,
      "kitten.png": 0,
      "turtle.png": 0,
      "mouse.png": 0,
      "monkey.png": 0,
      "giraffe.png": 0,
      "elephant.png": 0,
      "hedghog.png": 0,
      "crock.png": 0,
      "cow.png": 0,
      "chick.png": 0,
      "food.png": 0,
      "fun.png": 0,
      giveCreature: {},

      finalResults: [],
      gameOn: false,
      game: "",
      spinning: false,
      doors: Array.from({ length: 3 }, (_, index) => ({
        spinned: false,
        ref: React.createRef(),
      })),
      currentResults: Array(3).fill("❓"), // Initialize with default values
    };
    this.items = [
      "🐯",
      "🐶",
      "🐊",
      "🐵",

      "🦒",
      "🐱",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🐷",
      "🪂",
      "🐷",
      "🪂",
      "🐷",
      "🪂",
      "🐷",
      "🪂",
      "🐷",
      "🪂",
      "🐷",
      "🪂",
      "🐷",
      "🪂",
      "🐷",
      "🪂",
      "🐷",
      "🪂",
      "🐷",
      "🪂",
      "🐷",
      "🪂",
      "🐷",
      "🪂",
      "🐷",
      "🪂",
      "🐷",
      "🪂",
      "🐷",
      "🪂",
      "🐷",
      "🪂",
      "🐷",
      "🪂",
      "🐷",
      "🪂",
      "🐷",
      "🪂",
      "🐷",
      "🪂",
      "🐷",
      "🪂",
      "🐘",
      "🐣",
      "🐭",
      "🐮",
      "🐢",
      "🦔",
      // ...funList,
      ...foods,
    ];
  }
  resetGiveCreature = () => {
    this.setState({ giveCreature: {} });
  };
  getAnimalPosition = (item, position) => {
    // this.setState({ giveCreature: Math.random() });
    this.setState({ [item]: position });

    if (item === "food.png" || item === "fun.png") {
      Object.entries(this.state).forEach(([itemName, itemPosition]) => {
        if (
          itemName.endsWith(".png") &&
          itemName !== "fun.png" &&
          itemName !== "food.png"
        ) {
          const { top: currentItemTop, left: currentItemLeft } = itemPosition;
          const { top: newItemTop, left: newItemLeft } = position;

          const topDifference = Math.abs(currentItemTop - newItemTop);
          const leftDifference = Math.abs(currentItemLeft - newItemLeft);
          // this.setState({ giveCreature: Math.random() }, () => {});

          if (topDifference <= 20 && leftDifference <= 20) {
            this.setState({ giveCreature: { [itemName]: item } }, () => {
              // this.setState({ giveCreature: Math.random() });
            });
          }
        }
      });
    }
  };

  // updateCreature = async (creatureEmoji) => {
  //   //
  //   // if (Object.keys(animals).includes(creatureEmoji)) {
  //   let name = animals[creatureEmoji]["name"];

  //   this.setState(
  //     {
  //       [name]: this.state[name] + 1,
  //     },
  //     () => {}
  //   );
  //   let tamagochis = this.props.boughtTamagochies;

  //   tamagochis[creatureEmoji]["already_payed"] = this.state[name] + 1;

  //   addToLocalStorage("boughtTamagochies", tamagochis);
  //   // }
  // };
  toggleGame = (game) => {
    if (this.state.gameOn === true) {
      this.setState({ gameOn: false, game: "" });
    } else {
      this.setState({ gameOn: true, game: game });
    }
  };
  updateBonus = (type, list) => {
    let bonus = 0;
    for (let i = 0; i < 3; i++) {
      if (list.includes(this.state.finalResults[i])) {
        bonus = bonus + 1;
      }
    }
    let initialBonus = this.state[type];
    this.setState({ [type]: initialBonus + bonus, currentBonus: bonus });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.boughtTamagochies !== prevProps.boughtTamagochies) {
      // this.setState({
      //   tiger: this.props.boughtTamagochies["🐯"]["already_payed"],
      //   dog: this.props.boughtTamagochies["🐶"]["already_payed"],
      //   kitten: this.props.boughtTamagochies["🐱"]["already_payed"],
      //   turtle: this.props.boughtTamagochies["🐢"]["already_payed"],
      //   mouse: this.props.boughtTamagochies["🐭"]["already_payed"],
      //   monkey: this.props.boughtTamagochies["🐵"]["already_payed"],
      //   giraffe: this.props.boughtTamagochies["🦒"]["already_payed"],
      //   elephant: this.props.boughtTamagochies["🐘"]["already_payed"],
      //   hedghog: this.props.boughtTamagochies["🦔"]["already_payed"],
      //   crock: this.props.boughtTamagochies["🐊"]["already_payed"],
      //   cow: this.props.boughtTamagochies["🐮"]["already_payed"],
      //   chick: this.props.boughtTamagochies["🐣"]["already_payed"],
      // });
    }
    let { finalResults } = this.state;
    if (this.state.finalResults !== prevState.finalResults) {
      for (let i = 0; i < finalResults.length; i++) {
        const element = finalResults[i];
        if (
          [
            "🐯",
            "🐶",
            "🐊",
            "🐵",
            "🦒",
            "🐱",
            "🐘",
            "🐣",
            "🐭",
            "🐮",
            "🐢",
            "🦔",
          ].includes(element)
        ) {
          this.props.updateCreature(element).then(() => {
            // this.props.toggleModalRecieved(element);
          });
        }
      }
      this.updateBonus("foodBonus", foods);
      if (finalResults.every((result) => result === "🪂")) {
        this.toggleGame(
          <BalloonsPoppingGame
            upadateTotalScore={this.props.upadateTotalScore}
            balloonsPerMinute={200}
            isRunning={true}
            minimumSpeed={1}
            maximumSpeed={12}
          ></BalloonsPoppingGame>
        );
      } else if (finalResults.every((result) => result === "🐷")) {
        this.toggleGame(
          <RunnerGame
            upadateTotalScore={this.props.upadateTotalScore}
          ></RunnerGame>
        );
      }
    }
  };

  componentDidMount() {
    playAudio("life-of-a-wandering-wizard-15549.mp3", 0.2);
    this.init(true);
  }

  init(firstInit = true, groups = 1, duration = 1) {
    const { doors } = this.state;

    for (const [index, door] of doors.entries()) {
      if (firstInit) {
        door.spinned = false;
      } else if (door.spinned) {
        return;
      }

      const boxes = door.ref.current.querySelector(".boxes");
      const boxesClone = boxes.cloneNode(false);
      const pool = ["❓"];

      if (!firstInit) {
        const arr = [];
        for (let n = 0; n < (groups > 0 ? groups : 1); n++) {
          arr.push(...this.items);
        }
        pool.push(...this.shuffle(arr));

        boxesClone.addEventListener(
          "transitionstart",
          function () {
            door.spinned = true;
            this.querySelectorAll(".box").forEach((box) => {
              box.style.filter = "blur(1px)";
            });
          },
          { once: true }
        );

        boxesClone.addEventListener(
          "transitionend",
          () => {
            boxesClone.querySelectorAll(".box").forEach((box, i) => {
              box.style.filter = "blur(0)";
              if (i === 0 && !firstInit) {
                const newResults = [...this.state.currentResults];
                newResults[index] = box.textContent;
                this.setState({ currentResults: newResults });
              }
              if (i > 0) boxesClone.removeChild(box);
            });
          },
          { once: true }
        );
      }

      for (let i = pool.length - 1; i >= 0; i--) {
        const box = document.createElement("div");
        box.classList.add("box");
        box.style.width = `${door.ref.current.clientWidth}px`;
        box.style.height = `${door.ref.current.clientHeight}px`;
        box.textContent = pool[i];
        boxesClone.appendChild(box);
      }
      boxesClone.style.transitionDuration = `${duration > 0 ? duration : 1}s`;
      boxesClone.style.transform = `translateY(-${
        door.ref.current.clientHeight * (pool.length - 1)
      }px)`;
      door.ref.current.replaceChild(boxesClone, boxes);
    }
  }

  spin = async () => {
    if (this.state.spinning === false && this.props.coins - 10 >= 0) {
      playAudio("revolver-spin-96947.mp3", 1);
      this.props.buy("coins", 10);

      this.init(true);
      this.setState({ spinning: true });

      this.init(false, 1, 2);

      for (const door of this.state.doors) {
        const boxes = door.ref.current.querySelector(".boxes");
        const duration = parseInt(boxes.style.transitionDuration);
        boxes.style.transform = "translateY(0)";

        // Use the correct syntax for setTimeout
        await new Promise((resolve) => setTimeout(resolve, duration * 100));
      }

      // Set a timeout after the entire loop is completed
      setTimeout(() => {
        this.setState({
          spinning: false,
          finalResults: this.state.currentResults,
        });
      }, 2000);
    } else {
      this.props.toggleModal("noCoins");
    }
  };

  shuffle([...arr]) {
    let m = arr.length;
    while (m) {
      const i = Math.floor(Math.random() * m--);
      [arr[m], arr[i]] = [arr[i], arr[m]];
    }
    return arr;
  }

  render() {
    return (
      <div>
        <Modal
          maxWidth="100vw"
          height="100vh"
          width="100vw"
          isOpen={this.state.gameOn}
        >
          {this.state.game}
        </Modal>
        {/* <Bonus
          bonusTarget={this.state.bonus}
          bonusClass="bonus"
          inEffect="animate__zoomInDown"
          outEffect="animate__zoomOutDown"
          updatepoints={this.props.updatepoints}
          type="ballons"
          img={ballonsImg}
          bonus={1}
          audio="success-fanfare-trumpets-6185.mp3"
        ></Bonus> */}
        <Bonus
          bonusTarget={this.state.foodBonus}
          bonusClass="bonus"
          inEffect="animate__zoomInDown"
          outEffect="animate__zoomOutDown"
          updatepoints={this.props.updatepoints}
          audio={"eat_crunchy-40919.mp3"}
          type="food"
          img={"food.png"}
          bonus={this.state.currentBonus}
          // audio="success-fanfare-trumpets-6185.mp3"
        ></Bonus>

        <span className="slotMachineContainer">
          <div style={{ position: "absolute", bottom: "0px", width: "100%" }}>
            {" "}
            <CreaturesManu
              resetGiveCreature={this.resetGiveCreature}
              fun={this.state["fun.png"]}
              food={this.state["food.png"]}
              giveCreature={this.state.giveCreature}
              getAnimalPosition={this.getAnimalPosition}
              upadateTotalScore={this.props.upadateTotalScore}
              videoModal={this.props.videoModal}
              toggleModal={this.props.toggleModal}
              updateCreature={this.props.updateCreature}
              boughtTamagochies={this.props.boughtTamagochies}
              toggleModalRecieved={this.props.toggleModalRecieved}
            ></CreaturesManu>
          </div>
          {/* <img
            className="centered-item"
            src="slotmachine.png"
            alt="Slot Machine"
          /> */}
          <CountersBar
            counters={[
              <Counter
                symbol={coinImg}
                count={this.props.coins}
                spin={true}
                imgSize={"30px"}
                // top="10px"
                // left="30%"
              ></Counter>,
              <Counter
                symbol={diamondImg}
                count={this.props.diamonds}
                spin={true}
                imgSize={"30px"}
                // top="10px"
                // left="15%"
              ></Counter>,
              <Counter
                getAnimalPosition={this.getAnimalPosition}
                upadateTotalScore={this.props.upadateTotalScore}
                symbol={"food.png"}
                count={this.props.food}
                spin={false}
                imgSize={"100px"}
                // top="10px"
                // left="60%"
              ></Counter>,
              <Counter
                getAnimalPosition={this.getAnimalPosition}
                upadateTotalScore={this.props.upadateTotalScore}
                symbol={"fun.png"}
                count={this.props.fun}
                spin={false}
                imgSize={"100px"}
                // top="10px"
                // left="75%"
              ></Counter>,
            ]}
          ></CountersBar>
          <div className="slotMachine centered-item">
            {this.state.doors.map((door, index) => (
              <div
                key={index}
                className="door"
                ref={door.ref}
                data-spinned={door.spinned ? "1" : "0"}
              >
                <div className="boxes">
                  <div className="box">
                    {/* Replace emoji with image */}
                    <img
                      src={
                        this.state.currentResults[index] === "🪂"
                          ? "parachute.png"
                          : this.state.currentResults[index] === "🐷"
                          ? "pig.png"
                          : ""
                      }
                      alt={this.state.currentResults[index]}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* <div className="centered-item results">
          Results: {this.state.currentResults.join(" ")}
        </div> */}
          <button
            className="centered-item"
            onClick={this.spin}
            disabled={this.state.spinning}
          >
            {this.state.spinning ? "X" : "Spin"}
          </button>
        </span>
        {/* <Feedable></Feedable> */}
        {/* <GamesMenu></GamesMenu>{"s "} */}
        <span></span>
      </div>
    );
  }
}

export default SlotMachine;
