import React from "react";
import { read } from "./utils";

class LetterComponent extends React.Component {
  getRandomColor() {
    const colors = [
      "rgba(255, 190, 11, 1)", // amber
      "rgba(251, 86, 7, 1)", // orange-pantone
      "rgba(255, 0, 110, 1)", // rose
      "rgba(131, 56, 236, 1)", // blue-violet
      "rgba(58, 134, 255, 1)", // azure
    ];

    // Generate a random index within the array length
    const randomIndex = Math.floor(Math.random() * colors.length);

    // Access color by index
    const color = colors[randomIndex];

    return color;
  }

  render() {
    const { letter } = this.props;

    const letterStyle =
      this.props.color === true
        ? {
            color: this.getRandomColor(),
            cursor: "pointer",
          }
        : { color: "black" };

    return (
      <div>
        <p
          onClick={() =>
            this.props.selectLetter(letterStyle["color"], letter.toUpperCase())
          }
          className="letterContainer"
          style={letterStyle}
        >
          {letter}
        </p>
      </div>
    );
  }
}

export default LetterComponent;
