import axios from "axios";

// Filling the letters array with all the letters of the alphabet
export const letters = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

// Now, the letters array contains all lowercase letters from 'a' to 'z'
export const words = [
  "apple",
  "ball",
  "cat",
  "dog",
  "elephant",
  "fish",
  "guitar",
  "hat",
  "ice cream",
  "jigsaw puzzle",
  "kite",
  "lamp",
  "moon",
  "notebook",
  "orange",
  "pizza",
  "queen",
  "robot",
  "sun",
  "turtle",
  "umbrella",
  "violin",
  "watermelon",
  "xylophone",
  "yo-yo",
  "zeppelin",
  "acorn",
  "butterfly",
  "candle",
  "dolphin",
  "envelope",
  "firetruck",
  "giraffe",
  "hamburger",
  "igloo",
  "jellyfish",
  "key",
  "lemon",
  "mailbox",
  "nest",
  "octopus",
  "penguin",
  "quilt",
  "rainbow",
  "snail",
  "teapot",
  "unicorn",
  "volcano",
  "wizard",
  "zebra",
  "banana",
  "carrot",
  "diamond",
  "feather",
  "globe",
  "honey",
  "island",
  "jungle",
  "koala",
  "leopard",
  "magnet",
  "ninja",
  "olive",
  "puzzle",
  "quokka",
  "raindrop",
  "spaceship",
  "tiger",
  "wallet",
  "xylophone",
  "yogurt",
  "zipper",
  "airplane",
  "bracelet",
  "cloud",
  "drum",
  "earring",
  "flowerpot",
  "glasses",
  "harp",
  "igloo",
  "jacket",
  "kangaroo",
  "lemonade",
  "microphone",
  "necklace",
  "ocean",
  "piano",
  "quill",
  "raincoat",
  "sandcastle",
  "tornado",
  "umbrella",
  "vase",
  "wallet",
  "xylophone",
  "yogurt",
  "zipper",
  "apple",
  "ball",
  "cat",
  "dog",
  "elephant",
  "fish",
  "guitar",
  "hat",
  "ice cream",
  "jigsaw puzzle",
  "kite",
  "lamp",
  "moon",
  "notebook",
  "orange",
  "pizza",
  "queen",
  "robot",
  "sun",
  "turtle",
  "umbrella",
  "violin",
  "watermelon",
  "xylophone",
  "yo-yo",
  "zeppelin",
  "acorn",
  "butterfly",
  "candle",
  "dolphin",
  "envelope",
  "firetruck",
  "giraffe",
  "hamburger",
  "igloo",
  "jellyfish",
  "key",
  "lemon",
  "mailbox",
  "nest",
  "octopus",
  "penguin",
  "quilt",
  "rainbow",
  "snail",
  "teapot",
  "unicorn",
  "volcano",
  "wizard",
  "zebra",
  "banana",
  "carrot",
  "diamond",
  "feather",
  "globe",
  "honey",
  "island",
  "jungle",
  "koala",
  "leopard",
  "magnet",
  "ninja",
  "olive",
  "puzzle",
  "quokka",
  "raindrop",
  "spaceship",
  "tiger",
  "wallet",
  "xylophone",
  "yogurt",
  "zipper",
  "airplane",
  "bracelet",
  "cloud",
  "drum",
  "earring",
  "flowerpot",
  "glasses",
  "harp",
  "igloo",
  "jacket",
  "kangaroo",
  "lemonade",
  "microphone",
  "necklace",
  "ocean",
  "piano",
  "quill",
  "raincoat",
  "sandcastle",
  "tornado",
  "umbrella",
  "vase",
  "wallet",
  "xylophone",
  "yogurt",
  "zipper",
  // ... continue adding more items
];
export const getRandomWord = (wordArray) => {
  // Check if the array is not empty
  const randomIndex = Math.floor(Math.random() * wordArray.length);

  return wordArray[randomIndex];
};
export const read = (letter, volume = 1) => {
  // Cancel any ongoing speech synthesis
  window.speechSynthesis.cancel();

  const utterance = new SpeechSynthesisUtterance(letter);
  utterance.volume = volume;

  window.speechSynthesis.speak(utterance);
};
export const getImage = async (searchFor) => {
  const API_KEY = "21769897-d70f20c467afc476e0c319bc9";
  const URL = `https://pixabay.com/api/?key=${API_KEY}&q=${encodeURIComponent(
    searchFor
  )}`;

  try {
    const response = await axios.get(URL);
    if (parseInt(response.data.totalHits) > 0) {
      let picture = response.data.hits[0];
      return picture["largeImageURL"];
    } else {
      return null; // or throw an error if you prefer
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
let win1 = new Audio("cute-level-up-2-189851.mp3");
let win2 = new Audio("cute-level-up-3-189853.mp3");
let win3 = new Audio("game-bonus-144751.mp3");
let win4 = new Audio(
  "short-success-sound-glockenspiel-treasure-video-game-6346.mp3"
);
let win5 = new Audio("success-1-6297.mp3");
export let winNoise = [win1, win2, win3, win4, win5];
export const playRandomWinNoise = () => {
  const randomIndex = Math.floor(Math.random() * winNoise.length);
  winNoise[randomIndex].play();
};
export let repetAudio = async (audio, times) => {
  for (let i = 0; i < times; i++) {
    const clonedAudio = audio.cloneNode(); // Create a new instance of the audio element
    setTimeout(() => {
      clonedAudio
        .play()
        .then(() => {})
        .catch((error) => {
          console.error("Error playing audio:", error);
        });
    }, i * 100); // Introduce a delay between each play
  }
};
export let rainbow =
  "https://cdn.pixabay.com/photo/2017/05/02/17/16/rainbow-2278774_1280.png";
export let startListening = (targetWord) => {
  return new Promise((resolve, reject) => {
    read(`Please repeat the word ${targetWord}`);

    setTimeout(() => {
      if (
        "SpeechRecognition" in window ||
        "webkitSpeechRecognition" in window
      ) {
        const recognition = new (window.SpeechRecognition ||
          window.webkitSpeechRecognition)();
        recognition.lang = "en-US";

        recognition.onresult = function (event) {
          const result = event.results[0][0].transcript.toUpperCase();

          let testWord =
            `please repeat the word ${result} ${result}` ||
            `repeat the word ${result} ${result}` ||
            result
              ? result
              : "ghjgkhgggk";

          if (testWord.trim() === targetWord.toUpperCase()) {
            recognition.stop();
            resolve(true); // Resolving the Promise with true for a successful match
          } else {
            read("not good enough");
            recognition.stop();
            resolve(false); // Resolving the Promise with false for no match
          }
        };

        recognition.onerror = function (event) {
          console.error("Speech recognition error:", event.error);
          reject("Speech recognition error");
        };

        recognition.onend = function () {};

        recognition.start();
      } else {
        console.error("SpeechRecognition API not supported in this browser.");
        reject("SpeechRecognition API not supported");
      }
    }, 1500); // One-second delay
  });
};
export const playAudio = (audio, volume = 1) => {
  if (!(audio instanceof Audio)) {
    audio = new Audio(audio);
  }
  audio.volume = volume;

  audio
    .play()
    .then(() => {})
    .catch((error) => {
      console.error("Error playing audio:", error);
    });
};
// Example usage with a Promise
export let coinImg =
  "https://cdn.pixabay.com/photo/2019/04/15/20/46/bitcoin-4130319_960_720.png";

export let diamondImg =
  "https://cdn.pixabay.com/photo/2016/03/31/21/18/diamond-1296317_1280.png";
export let ballonsImg =
  "https://cdn.pixabay.com/photo/2012/04/25/00/27/balloons-41362_960_720.png";
export const thirdPlace =
  "https://cdn.pixabay.com/photo/2016/08/26/16/04/medal-1622549_1280.png";

export const firstPlace =
  "https://cdn.pixabay.com/photo/2016/08/26/15/56/medal-1622523_960_720.png";

export const secondPlace =
  "https://cdn.pixabay.com/photo/2016/08/26/15/59/medal-1622529_1280.png";
export const avatars = [
  "avatar1.png",
  "avatar2.png",
  "avatar3.png",
  "avatar4.png",
  "avatar5.png",
  "avatar6.png",
  "avatar7.png",
  "avatar8.png",
  "avatar9.png",
  "avatar10.png",
  "avatar11.png",
  "avatar12.png",
  "avatar13.png",
  "avatar14.png",
  "avatar15.png",
  "avatar16.png",
  "avatar17.png",
  "avatar18.png",
  "avatar19.png",
  "avatar20.png",
  "avatar21.png",
  "avatar22.png",
  "avatar23.png",
  "avatar24.png",
  "avatar25.png",
  "avatar26.png",
  "avatar27.png",
  "avatar28.png",
  "avatar29.png",
  "avatar30.png",
  "avatar31.png",
  "avatar32.png",
  "avatar33.png",
  "avatar34.png",
  "avatar35.png",
  "avatar36.png",
];
export let animals =
  // Define an object of animal objects with filenames as keys
  {
    // "whitetiger": {
    //   image: "whitetiger.png",
    //   was_bought: true,
    //   price: 1,
    //   already_payed: 0,
    // },
    "🐯": {
      name: "tiger",
      image: "tiger.png",
      was_bought: false,
      price: 2,
      already_payed: 0,
      emoji: "🐯",
      recievedModal: false,
      video: 528346866,
      sound: "tiger-creature-roar-fantasy-monster-sfx-201129_0081-95599.mp3",
    },
    "🐵": {
      name: "monkey",
      image: "monkey.png",
      was_bought: false,
      price: 4,
      already_payed: 0,
      emoji: "🐵",
      recievedModal: false,
      video: 703166195,
      sound: "monkey-128368.mp3",
    },
    "🐊": {
      name: "crock",
      image: "crock.png",
      was_bought: false,
      price: 8,
      already_payed: 0,
      emoji: "🐊",
      recievedModal: false,
      video: 849096633,
      sound: "tiny-croc-chirp-40638.mp3",
    },
    // "lemur": {
    // name:
    //   image: "lemur.png",
    //   was_bought: true,
    //   price: 16,
    //   already_payed: 0,
    // },
    "🐶": {
      name: "dog",
      image: "dog.png",
      was_bought: false,
      price: 32,
      already_payed: 0,
      emoji: "🐶",
      recievedModal: false,
      video: 565388778,
      sound: "dog_barking-6296.mp3",
    },
    "🐱": {
      name: "kitten",
      image: "kitten.png",
      was_bought: false,
      price: 64,
      already_payed: 0,
      emoji: "🐱",
      recievedModal: false,
      video: 520278992,
      sound: "cat-meow-14536.mp3",
    },
    "🦒": {
      name: "giraffe",
      image: "giraffe.png",
      was_bought: false,
      price: 128,
      already_payed: 0,
      emoji: "🦒",
      recievedModal: false,
      video: 441932666,
      sound: "cute-character-wee-1-188162.mp3",
    },
    // "ferret": {
    //   image: "ferret.png",
    //   was_bought: true,
    //   price: 256,
    //   already_payed: 0,
    // },
    "🐘": {
      name: "elephant",
      image: "elephant.png",
      was_bought: false,
      price: 512,
      already_payed: 0,
      emoji: "🐘",
      recievedModal: false,
      video: 371858947,
      sound: "elephant-trumpets-growls-6047.mp3",
    },
    "🐢": {
      name: "turtle",
      image: "turtle.png",
      was_bought: false,
      price: 1024,
      already_payed: 0,
      emoji: "🐢",
      recievedModal: false,
      video: 746551694,
      sound: "brass-fanfare-with-timpani-and-winchimes-reverberated-146260.mp3",
    },
    "🐮": {
      name: "cow",
      image: "cow.png",
      was_bought: false,
      price: 2048,
      already_payed: 0,
      emoji: "🐮",
      recievedModal: false,
      video: 440310510,
      sound: "mooing-cow-122255.mp3",
    },
    "🦔": {
      name: "hedghog",
      image: "hedghog.png",
      was_bought: false,
      price: 4096,
      already_payed: 0,
      emoji: "🦔",
      recievedModal: false,
      video: 637382686,
      sound: "innocent-whistle-106754.mp3",
    },
    "🐣": {
      name: "chick",
      image: "chick.png",
      was_bought: false,
      price: 8192,
      already_payed: 0,
      emoji: "🐣",
      recievedModal: false,
      video: 454154875,
      sound: "short-chick-sound-171389.mp3",
    },
    "🐭": {
      name: "mouse",
      image: "mouse.png",
      was_bought: false,
      price: 8192,
      already_payed: 0,
      emoji: "🐣",
      recievedModal: false,
      video: 340202284,
      sound: "mouse-6821.mp3",
    },
  };

// Export the object of animal objects

export let gamesPictures = [
  { "lettersgame - screenshot.png": "#/letters-game" },
  { "ballons-screenshot.png": "#/balloons" },
  { "Screenshot - 2024-03-12T142633.397.png": "#/slot" },
];
export let foods = [
  "🍧",
  "🍨",
  "🍩",
  "🍪",
  "🎂",
  "🍰",
  "🧁",
  "🥧",
  "🍫",
  // "🍬",
  // "🍭",
  // "🍮",
  // "🍯",
  // "🍞",
  // "🍈",
  // "🍉",
  // "🍊",
  // "🍋",
  // "🍌",
  // "🍍",
  // "🥭",
  // "🍎",
  // "🍏",
  // "🍐",
  // "🍑",
  // "🍒",
  // "🍓",
  // "🫐",
  // "🥝",
  // "🍅",
  // "🫒",
  // "🥥",
  // "🥐",
  // "🥖",
  // "🫓",
  // "🥨",
  // "🥯",
  // "🥞",
  // "🧇",
  // "🧀",
  // "🍖",
  // "🍗",
  // "🥩",
  // "🥓",
  // "🍔",
  // "🍟",
  // "🍕",
  // "🌭",
  // "🥪",
  // "🌮",
  // "🌯",
];
export let funList = [
  "🪀",
  "🪁",
  "🔫",
  "🎱",
  "🔮",
  "🪄",
  "🎮",
  "🕹️",
  "🎰",
  "🎲",
  "🧩",
  "🪅",
  "🪩",
  "🪆",
  "♠️",
  "♥️",
  "♦️",
  "♣️",
  "♟️",
  "🃏",
  "🀄",
  "🎴",
  "🎭",
  "🖼️",
  "🎨",
  "📱",
  "📲",
  "☎️",
  "📞",
  "📟",
  "📠",
  "🔋",
  "🪫",
  "🔌",
  "💻",
  "🖥️",
  "🖨️",
  "⌨️",
  "🖱️",
  "🖲️",
  "💽",
  "💾",
  "💿",
  "📀",
  "🎥",
  "🎞️",
  "📽️",
  "🎬",
  "📺",
  "📷",
  "📸",
  "📹",
  "📼",
];
export let addToLocalStorage = (key, data) => {
  try {
    localStorage.setItem(key, JSON.stringify(data));
  } catch (error) {
    console.error("Error adding data to local storage:", error);
  }
};
export let retrieveFromLocalStorage = (key) => {
  try {
    const data = localStorage.getItem(key);
    if (data === null) {
      return null;
    }
    return JSON.parse(data);
  } catch (error) {
    console.error("Error retrieving data from local storage:", error);
    return null;
  }
};
export let obsticals = [
  "https://cdn.pixabay.com/animation/2024/02/18/15/43/15-43-53-196_512.gif",
  "https://cdn.pixabay.com/animation/2023/04/17/10/21/10-21-08-304_512.gif",
  "https://cdn.pixabay.com/animation/2022/11/12/08/51/08-51-46-573_512.gif",
  "https://cdn.pixabay.com/animation/2024/02/25/18/29/18-29-54-480_512.gif",
  "https://cdn.pixabay.com/animation/2023/07/08/11/26/11-26-17-598_512.gif",
  "https://cdn.pixabay.com/animation/2024/02/07/11/08/11-08-35-736_512.gif",
  "https://media2.giphy.com/media/xUA7aZeLE2e0P7Znz2/giphy.gif",
];
export const getRandomElementFromArray = (array) => {
  // Generate a random index within the bounds of the array
  const randomIndex = Math.floor(Math.random() * array.length);
  // Return the element at the random index
  return array[randomIndex];
};

export let getElementPosition = (event) => {
  const element = event.target;

  let handleMove = () => {
    const rect = element.getBoundingClientRect();
  };

  element.addEventListener("mousemove", handleMove);

  // Returning a cleanup function to remove the event listener when needed
  return () => {
    element.removeEventListener("mousemove", handleMove);
  };
};
export let getBoundingClientRect = (element) => {
  const rect = element.getBoundingClientRect();

  return {
    top: rect.top + window.screenY,
    left: rect.left + window.scrollX,
    // bottom: rect.bottom + window.screenY,
    // right: rect.right + window.scrollX,
    // width: rect.width,
    // height: rect.height,
  };
};
export const domain = "https://recoveril.com/";
// http://localhost:3000/
