import React, { Component } from "react";

class VerticalProgressBar extends Component {
  render() {
    const { maxValue, currentValue } = this.props;
    const percentage = (currentValue / maxValue) * 100;

    return (
      <div
        style={{
          height: "50%",
          bottom: "22px",
          left: "-3px",
          width: "15px",
          backgroundColor: "rgb(240, 240, 240)",
          position: "absolute",
        }}
      >
        <img
          style={{
            height: "176%",
            bottom: "-16px",
            left: "-48px",
            position: "absolute",
            margin: "0px",
          }}
          src="testtube.png"
        ></img>

        <div
          className="water-effect"
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            height: `${percentage}%`,
            width: "15px",
            transition: "height 0.2s",
          }}
        >
          <img
            style={{
              //   height: "129%",
              bottom: " -16px",
              left: "1px",
              //   position: "absolute",
              margin: "0px",
              width: "100%",
              height: "auto",
              zIndex: 100,
              opacity: 0.6,
            }}
            src="bubble-7794_256.gif"
          ></img>
          <img
            style={{
              //   height: "129%",
              bottom: " -16px",
              left: "1px",
              //   position: "absolute",
              margin: "0px",
              width: "100%",
              height: "auto",
              zIndex: 100,
              opacity: 0.2,
            }}
            src="bubble-7794_256.gif"
          ></img>
        </div>
      </div>
    );
  }
}

export default VerticalProgressBar;
