import React, { Component } from "react";

export default class Gallery extends Component {
  galleryInnerRef = React.createRef();

  handleLeftArrowClick = () => {
    if (this.galleryInnerRef.current) {
      this.galleryInnerRef.current.scrollBy({
        left: -window.innerWidth,
        behavior: "smooth",
      });
    }
  };

  handleRightArrowClick = () => {
    if (this.galleryInnerRef.current) {
      this.galleryInnerRef.current.scrollBy({
        left: window.innerWidth,
        behavior: "smooth",
      });
    }
  };

  render() {
    const { elements } = this.props;

    return (
      <div className="galleryOuter">
        <button
          className="arrow left-arrow"
          onClick={this.handleLeftArrowClick}
        >
          &lt;
        </button>
        <button
          className="arrow right-arrow"
          onClick={this.handleRightArrowClick}
        >
          &gt;
        </button>
        <div className="galleryInner" ref={this.galleryInnerRef}>
          {elements.map(
            (element, index) =>
              // <img
              //   className="chooseAvatar"
              //   onClick={() => this.props.callback(element)}
              //   key={index}
              //   src={element}
              //   alt={`Image ${index + 1}`}
              //   style={
              //     this.props.choosenElement === element
              //       ? { border: "2px solid #FFD700" }
              //       : { border: "" } // Adjust the border style as needed
              //   }
              // />
              element
          )}
        </div>
      </div>
    );
  }
}
