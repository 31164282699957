import React, { Component } from "react";
import ChosenWord from "./ChosenWord";

export default class extends Component {
  render() {
    return (
      <div className="WordComponentContainer">
        <ChosenWord
          currentLocation={this.props.currentLocation}
          selectedWord={this.props.selectedWord.toUpperCase()}
        ></ChosenWord>
      </div>
    );
  }
}
