import React, { Component } from "react";
import MainAvatar from "../components.js/MainAvatar";
import GamesMenu from "../components.js/GamesMenu";
import Counter from "../components.js/Counter";
import { coinImg, diamondImg } from "../components.js/utils";

export default class Home extends Component {
  render() {
    return (
      <div className="HomeContainer">
        <h2 className="HomeHeadline">home</h2>
        <Counter
          symbol={coinImg}
          count={this.props.coins}
          spin={true}
          imgSize={"30px"}
          top="10px"
          left="30%"
        ></Counter>
        <Counter
          symbol={diamondImg}
          count={this.props.diamonds}
          spin={true}
          imgSize={"30px"}
          top="10px"
          left="15%"
        ></Counter>

        <MainAvatar></MainAvatar>
        <GamesMenu toggleModal={() => {}}></GamesMenu>
        {/* <CreaturesMenu></CreaturesMenu> */}
      </div>
    );
  }
}
