import React, { Component } from "react";
import Counter from "../components.js/Counter";
import { playAudio } from "../components.js/utils";
import Timer from "../components.js/Timer";
import Modal from "../components.js/Modal";

class BalloonsPoppingGame extends Component {
  constructor(props) {
    super(props);
    this.state = {
      balloons: [],
      count: 0,
      isRunning: true,
      isOpen: false,
    };
  }
  toggleModal = () => {
    let status = this.state.isOpen === true ? false : true;
    this.setState({ isOpen: status });
  };
  pauseGame = () => {
    this.setState({ isRunning: false }, () => this.toggleModal());
    this.props.upadateTotalScore("fun", this.state.count);
  };
  countPops = () => {
    let count = this.state.count;
    count = count + 1;
    this.setState({ count: count });
  };
  componentDidMount() {
    setInterval(() => {}, 4000);
    this.balloonInterval = setInterval(() => {
      if (this.props.isRunning) {
        this.generateRandomBalloon();
      }
    }, 60000 / this.props.balloonsPerMinute);

    this.moveInterval = setInterval(this.moveBalloons, 1000 / 60); // Update every 1/60th of a second
  }

  componentWillUnmount() {
    clearInterval(this.balloonInterval);
    clearInterval(this.moveInterval);
  }
  generateRandomBalloon = () => {
    const color = this.getRandomColor();
    const speed = this.getRandomSpeed(
      this.props.minimumSpeed,
      this.props.maximumSpeed
    );
    const left =
      Math.random() * (window.screen.width - window.screen.width * 0.3); // Use window.innerWidth
    const balloon = {
      id: Date.now(),
      color,
      speed,
      position: { top: window.innerHeight, left },
    };

    this.setState((prevState) => ({
      balloons: [...prevState.balloons, balloon],
    }));
  };

  moveBalloons = () => {
    if (this.props.isRunning) {
      this.setState((prevState) => ({
        balloons: prevState.balloons.map((balloon) => ({
          ...balloon,
          position: {
            ...balloon.position,
            top: balloon.position.top - balloon.speed,
          },
        })),
      }));
    }
  };

  popBalloon = (id) => {
    playAudio("pop-39222.mp3", 0.5);
    this.setState((prevState) => ({
      balloons: prevState.balloons.filter((balloon) => balloon.id !== id),
    }));
    this.countPops();
  };

  getRandomColor = () => {
    const colors = ["#FF5733", "#33FF57", "#5733FF", "#FF3357", "#57FF33"];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  getRandomSpeed = (min, max) => {
    return Math.random() * (max - min) + min;
  };

  render() {
    let content =
      this.state.isRunning === true
        ? this.state.balloons.map((balloon) => (
            <div
              key={balloon.id}
              className="balloon"
              onClick={() => this.popBalloon(balloon.id)}
              style={{
                position: "absolute",
                top: balloon.position.top,
                left: balloon.position.left,
                //   borderRadius: "50%",
                //   width: "50px",
                //   height: "50px",
                cursor: "pointer",
              }}
            >
              <div
                className="ball"
                style={{ backgroundColor: balloon.color }}
              ></div>
              <div className="stick"></div>
            </div>
          ))
        : "";
    return (
      <div style={{ width: "100% !IMPORTANT" }}>
        {" "}
        <Counter
          top={"20px"}
          bottom={""}
          right={""}
          left={"30px"}
          symbol={
            "https://cdn.pixabay.com/photo/2012/04/25/00/27/balloons-41362_960_720.png"
          }
          count={this.state.count}
        ></Counter>
        <div style={{ maxWidth: "100%" }}>
          {" "}
          <Timer
            // callback={this.pauseGame}
            initialTime={60}
            isCounting={true}
            highestValue={60}
            callback={this.pauseGame}
          ></Timer>
          <Modal isOpen={this.state.isOpen} closeButton={false}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                height: "30px",
              }}
            >
              <img src="https://cdn.pixabay.com/photo/2012/04/25/00/27/balloons-41362_960_720.png"></img>
              <img src="https://cdn.pixabay.com/photo/2012/04/25/00/27/balloons-41362_960_720.png"></img>
            </div>
            <h2>wow</h2>
            <div>{`you popped ${this.state.count} ballons!! `}</div>
            <button onClick={() => window.location.assign("/")}>
              {" "}
              back to home page
            </button>
          </Modal>
        </div>
        {content}
      </div>
    );
  }
}

export default BalloonsPoppingGame;
