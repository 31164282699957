import React, { Component } from "react";
import { letters } from "./utils";
import LetterComponent from "./LetterComponent";

export default class AllLetters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allLetters: "",
    };
  }
  componentDidMount() {
    let allLetters = letters.map((letter) => {
      return (
        <LetterComponent
          selectLetter={this.props.selectLetter}
          color={true}
          letter={letter}
        ></LetterComponent>
      );
    });
    this.setState({ allLetters: allLetters });
  }
  render() {
    return <div className="allLettersConainer">{this.state.allLetters}</div>;
  }
}
