import React, { Component } from "react";
import "./App.css";

import "animate.css";

import {
  addToLocalStorage,
  animals,
  getRandomWord,
  playAudio,
  playRandomWinNoise,
  read,
  retrieveFromLocalStorage,
  startListening,
  words,
} from "./components.js/utils";
// import "bootstrap/dist/css/bootstrap.min.css";

import Modal from "./components.js/Modal";

import LettersGame from "./pages/LettersGame";
import { HashRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import SlotMachine from "./pages/SlotMachine";

import VimeoFrame from "./components.js/VimeoFrame";
import GamesMenu from "./components.js/GamesMenu";

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedColor: "",
      boughtTamagochies: "",
      selectedLetter: "",
      selectedWord: "",
      currentLocation: 0,
      revieldLetters: "",
      spellWord: "",
      audio: "",
      timeLeftForLetter: 0,
      isCounting: false,
      points: 0,
      muted: false,
      bonus: "", // New muted property
      diamonds: 0,
      balloons: 0,
      gameTime: 0,
      isGameCounting: false,
      bestScore: 0,
      modalOpen: false,
      sessionScoreCoins: 0,
      allTimeScoreCoins: 0,
      bestScoreCoins: 0,
      sessionScorediamonds: 0,
      allTimeScorediamonds: 0,
      bestScorediamonds: 0,
      startModal: false,
      avatar: "",
      food: 0,
      fun: 0,
      videoModal: false,
      selectedVideo: "",
      selectedSound: "",
      noCoins: false,
    };
  }

  toggleModal = (type, element) => {
    let value = this.state[type] === false ? true : false;
    this.setState({ [type]: value });
    if (element) {
      this.setState({
        selectedVideo: element.video,
        selectedSound: element.sound,
      });
      setTimeout(() => {
        // this.setState({ showVideo: false }, this.props.toggleModal("videoModal"));
      }, 6000);
    }
  };
  updatePrize = (prize, amount) => {
    this.setState({ [prize]: amount });
  };
  upadateTotalScore = (currency, sum, action) => {
    let state = currency === "points" ? "allTimeScoreCoins" : "";
    state = currency === "coins" ? "allTimeScoreCoins" : state;

    state = currency === "food" ? "food" : state;
    state = currency === "fun" ? "fun" : state;

    state = currency === "diamonds" ? "allTimeScorediamonds" : state;

    let currentAmount = parseInt(localStorage.getItem(currency), 10) || 0; // Default to 0 if the item doesn't exist
    let newAmount =
      action === "remove" ? currentAmount - sum : currentAmount + sum;

    this.setState({ [state]: newAmount }, () => {
      localStorage.setItem(currency, newAmount.toString());
    });
  };

  buy = (currency, sum) => {
    //

    let state = currency === "coins" ? "allTimeScoreCoins" : "";
    // state = state === "diamonds" ? "allTimeScorediamonds" : "";
    let currentAmount = parseInt(this.state[state], 10) || 0; // Default to 0 if the state property doesn't exist
    let newAmount = currentAmount - sum;

    this.setState(
      { [state]: newAmount }
      // () =>
      // localStorage.setItem(state, newAmount.toString())
    );
  };

  openModal = (state) => {
    this.setState({ [state]: true });
  };

  closeModal = (state) => {
    this.setState({ [state]: false });
  };

  toggleMute = () => {
    const { audio } = this.state;
    audio.muted = !audio.muted;
    this.setState((prevState) => ({
      muted: !prevState.muted,
    }));
  };

  startCountdown = (state, countState, initialTime) => {
    clearInterval(this.intervalId); // Clear existing interval
    this.setState({ [state]: initialTime, [countState]: true }, () => {
      this.intervalId = setInterval(() => {
        this.setState((prevState) => {
          if (prevState[state] > 0) {
            return { [state]: prevState[state] - 1 };
          } else {
            this.stopCountdown(countState);
            return null;
          }
        });
      }, 1000);
    });
  };

  stopCountdown = (countType, callback) => {
    clearInterval(this.intervalId);
    this.setState({ [countType]: false }, callback);
  };

  getTimeLeftForLetter = () => {
    return this.state.timeLeftForLetter;
  };

  trigerBonus = (points, multiplier, type) => {
    let bonus = points * multiplier;
    this.setState({ [type]: bonus });
  };

  handleKeyDown = (event) => {
    // Check if the pressed key is a letter (you may want to add more conditions if needed)
    if (event.key.match(/[a-z]/i)) {
      // Call your selectLetter function with the color and the pressed letter
      this.selectLetter("yourColor", event.key.toUpperCase());
    }
  };

  startGame = () => {
    this.closeModal("startModal");
    this.setState({
      selectedWord: getRandomWord(words).toUpperCase(),
      timeLeftForLetter: 10,
      gameTime: 20,
      points: 0,
      diamonds: 0,
      spellWord: "",
      currentLocation: 0,
    });

    this.startCountdown("gameTime", "isGameCounting", 20);
    this.startCountdown("timeLeftForLetter", "isCounting", 10);
  };

  finishGame = (score) => {
    this.stopCountdown("gameTime");
    this.openModal("modalOpen");
    this.setState({ selectedWord: "" });

    const currentDate = new Date();
    const options = {
      timeZone: "Asia/Jerusalem",
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };

    const formattedDate = currentDate.toLocaleString("en-US", options);

    const gameResult = {
      time: formattedDate,
      score: score,
      avatar: this.state.avatar,
    };

    let topScores = JSON.parse(localStorage.getItem("topScores")) || [];
    topScores.push(gameResult);

    topScores.sort((a, b) => (b.score || 0) - (a.score || 0));
    const topFiveScores = topScores.slice(0, 5);
    localStorage.setItem("topScores", JSON.stringify(topFiveScores));
    this.setState({ bestScoreCoins: topFiveScores });

    this.setState({ selectedWord: "" });
  };
  toggleModalRecieved = (emoji) => {
    if (
      this.state.boughtTamagochies[emoji].was_bought === true &&
      this.state.boughtTamagochies[emoji].recievedModal === false
    ) {
      let tamagochie = this.state.boughtTamagochies[emoji];

      tamagochie["recievedModal"] = true;

      let boughtTamagochies = this.state.boughtTamagochies;
      boughtTamagochies[emoji] = tamagochie;
      addToLocalStorage("boughtTamagochies", boughtTamagochies);
    }
  };
  updateCreature = async (creatureEmoji, field) => {
    //
    // if (Object.keys(animals).includes(creatureEmoji)) {
    // let name = animals[creatureEmoji]["name"];
    let tamagochis = this.state.boughtTamagochies;
    if (tamagochis[creatureEmoji]["was_bought"] === false) {
      tamagochis[creatureEmoji]["already_payed"] =
        tamagochis[creatureEmoji]["already_payed"] + 1;
      this.setState(
        {
          boughtTamagochies: tamagochis,
        },
        () => {}
      );

      if (
        tamagochis[creatureEmoji]["already_payed"] ===
        tamagochis[creatureEmoji]["price"]
      ) {
        tamagochis[creatureEmoji]["was_bought"] = true;
        tamagochis[creatureEmoji]["already_payed"] = 0;
        // tamagochis[creatureEmoji]["recievedModal"] = true;
      }
      addToLocalStorage("boughtTamagochies", tamagochis);
    }
    // }
  };
  componentDidMount() {
    let tamagochies = retrieveFromLocalStorage("boughtTamagochies");

    if (tamagochies !== null && tamagochies !== undefined) {
      this.setState({ boughtTamagochies: tamagochies });
    } else {
      addToLocalStorage("boughtTamagochies", animals);
    }

    // this.setState({ boughtTamagochies: tamagochies });
    this.setState({ startModal: true });
    document.addEventListener("keydown", this.handleKeyDown);
    let diamonds = parseInt(localStorage.getItem("diamonds"), 10) || 0;
    let coins = parseInt(localStorage.getItem("points"), 10) || 0;
    let food = parseInt(localStorage.getItem("food"), 10) || 0;
    let fun = parseInt(localStorage.getItem("fun"), 10) || 0;

    this.setState(
      {
        allTimeScoreCoins: coins,
        allTimeScorediamonds: diamonds,
        food: food,
        fun: fun,
      },
      () => {}
    );
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  testWord = (word) => {
    if (
      word[this.state.currentLocation] !==
      this.state.selectedLetter.toUpperCase()
    ) {
      return false;
    } else this.setState({ currentLocation: this.state.currentLocation + 1 });

    return true;
  };

  updatepoints = (points, type, sound = "") => {
    points = points > 1 ? points : 1;
    const updatePointsWithDelay = async () => {
      for (let i = 0; i < points; i++) {
        await new Promise((resolve) => {
          this.setState(
            (prevState) => ({
              [type]: prevState[`${type}`] + 1,
            }),

            resolve
          );
          this.upadateTotalScore(type, 1);
          if (sound !== "") {
            let audio = new Audio(sound);

            playAudio(audio, 1);
          }
        });

        await new Promise((resolve) => setTimeout(resolve, 100));
      }
    };

    updatePointsWithDelay();
  };

  chooseAvatar = (id) => {
    this.setState({ avatar: id });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.boughtTamagochies !== prevState.boughtTamagochies &&
      this.state.boughtTamagochies !== null &&
      this.state.boughtTamagochies !== undefined
    ) {
      addToLocalStorage("boughtTamagochies", this.state.boughtTamagochies);
    }
    if (this.state.allTimeScoreCoins !== prevState.allTimeScoreCoins) {
      localStorage.setItem("points", this.state.allTimeScoreCoins.toString());
    }
    if (
      this.state.gameTime === 0 &&
      this.state.gameTime !== prevState.gameTime
    ) {
      // this.finishGame(this.state.points);
    }
    if (
      this.state.points !== prevState.points &&
      Math.floor(this.state.points / 100) !== Math.floor(prevState.points / 100)
    ) {
      this.trigerBonus(Math.floor(this.state.points / 100) * 100, 2, "bonus");
    }
    if (this.state.selectedWord !== prevState.selectedWord) {
      startListening(this.state.selectedWord)
        .then((res) => {
          if (res) {
            this.updatepoints(10, "points");
            this.setState({ timeLeftForLetter: 10 });
          } else {
          }
        })
        .catch((e) => {});
    }

    if (this.state.spellWord !== prevState.spellWord) {
      const letter = this.state.selectedWord[this.state.currentLocation];

      if (/^[a-zA-Z]$/.test(letter) === false) {
        this.setState({
          spellWord:
            this.state.spellWord +
            this.state.selectedWord[this.state.currentLocation],
          currentLocation: this.state.currentLocation + 1,
        });
      }
      this.startCountdown("timeLeftForLetter", "isCounting", 10);
    }

    if (
      this.state.spellWord.length === this.state.selectedWord.length &&
      this.state.spellWord !== prevState.spellWord
    ) {
      if (
        this.state.selectedWord.length === this.state.spellWord.length &&
        this.state.spellWord !== prevState.spellWord &&
        this.state.spellWord.length !== 0
      ) {
        this.setState({
          selectedWord: getRandomWord(words).toUpperCase(),
          spellWord: "",
          currentLocation: 0,
          selectedLetter: "",
        });
        playRandomWinNoise();
      }
    }
  }

  tryAgain = () => {
    this.closeModal("modalOpen");
    this.openModal("startModal");
  };

  getAtribute = (atribute, value) => {
    this.setState({ atribute: value });
  };

  selectLetter = (color, letter) => {
    read(letter);

    this.setState(
      { selectedColor: color, selectedLetter: letter.toUpperCase() },
      () => {
        if (this.testWord(this.state.selectedWord) === true) {
          let revieldLetters = this.state.spellWord;

          this.setState({
            spellWord: revieldLetters + letter,
          });
        } else {
          playAudio("wrong-38598.mp3", 0.3);
        }
      }
    );
  };

  render() {
    return (
      <div className="app">
        <Modal isOpen={this.state.videoModal} width="100%" height={"100%"}>
          {" "}
          <VimeoFrame
            // auto={true}
            toggleModal={this.toggleModal}
            video={this.state.selectedVideo}
            sound={this.state.selectedSound}
          ></VimeoFrame>
          ;
        </Modal>
        <Modal isOpen={this.state.noCoins}>
          <h2>oops, you run out of coins</h2>
          <div>you can play this games to get more coins</div>
          {/* <div style={{ position: "relative", bottom: "0px", width: "90%" }}> */}
          <GamesMenu toggleModal={this.toggleModal}></GamesMenu>
          {/* </div> */}
        </Modal>
        <HashRouter>
          <Routes>
            {/* <Route
              path="/RunnerGame"
              element={<RunnerGame></RunnerGame>}
            ></Route> */}
            <Route
              path="/slot"
              element={
                <SlotMachine
                  // updatePrize

                  upadateTotalScore={this.upadateTotalScore}
                  fun={this.state.fun}
                  toggleModalRecieved={this.toggleModalRecieved}
                  videoModal={this.state.videoModal}
                  toggleModal={this.toggleModal}
                  updateCreature={this.updateCreature}
                  food={this.state.food}
                  trigerBonus={this.trigerBonus}
                  diamonds={this.state.allTimeScorediamonds}
                  coins={this.state.allTimeScoreCoins}
                  buy={this.buy}
                  updatepoints={this.updatepoints}
                  boughtTamagochies={this.state.boughtTamagochies}
                ></SlotMachine>
              }
            ></Route>
            {/* <Route
              path="/tamagochi"
              element={
                <Creature
                  animal={"tamagochi"}
                  // updatePrize=
                ></Creature>
              }
            ></Route> */}

            <Route
              path="/"
              element={
                <Home
                  toggleModal={this.toggleModal}
                  coins={this.state.allTimeScoreCoins}
                  diamonds={this.state.allTimeScorediamonds}
                ></Home>
              }
            ></Route>
            {/* <Route
              path="/balloons"
              element={
                <BalloonsPoppingGame
                  balloonsPerMinute={100}
                  isRunning={true}
                  minimumSpeed={1}
                  maximumSpeed={8}
                  upadateTotalScore={this.upadateTotalScore}
                />
              }
            ></Route> */}

            <Route
              path="/letters-game"
              element={
                <LettersGame
                  audio={this.state.audio}
                  toggleMute={this.toggleMute}
                  currentLocation={this.state.currentLocation}
                  selectLetter={this.selectLetter}
                  bonus={this.state.bonus}
                  updatepoints={this.updatepoints}
                  gameTime={this.state.gameTime}
                  isGameCounting={this.state.isGameCounting}
                  spellWord={this.state.spellWord}
                  points={this.state.points}
                  finishGame={this.finishGame}
                  isCounting={this.state.isCounting}
                  timeLeftForLetter={this.state.timeLeftForLetter}
                  avatar={this.state.avatar}
                  diamonds={this.state.diamonds}
                  modalOpen={this.state.modalOpen}
                  closeModal={this.closeModal}
                  startModal={this.state.startModal}
                  chooseAvatar={this.chooseAvatar}
                  startGame={this.startGame}
                  tryAgain={this.tryAgain}
                  selectedWord={this.state.selectedWord}
                  bestScoreCoins={this.state.bestScoreCoins}
                />
              }
            ></Route>
          </Routes>
        </HashRouter>
      </div>
    );
  }
}
