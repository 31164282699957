import React, { Component } from "react";
import { playAudio } from "./utils";

export default class Bonus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bonusTarget: "",
      bonus: "",
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (this.props.bonusTarget !== prevProps.bonusTarget &&
        this.props.bonusTarget > prevProps.bonusTarget) ||
      this.props.bonusTarget === undefined
      //    &&
      //   this.state.bonus !== this.props.bonusTarget
    ) {
      // Change the state only if the bonus prop has changed
      this.setState({ bonusTarget: this.props.bonusTarget }, () => {
        this.updateClass();
        this.setState({
          bonus: (
            <div>
              {" "}
              +{this.props.bonus}{" "}
              <img className="bonusImg" src={this.props.img}></img>
            </div>
          ),
        });
        let audio = new Audio(this.props.audio);

        playAudio(audio, 1);
        this.props.updatepoints(
          this.props.bonus,
          this.props.type
          // this.props.audio
        );

        // Revert the class after one second
        setTimeout(() => {
          this.revertClass();
        }, 1000);
      });
    } else if (
      this.props.bonusTarget !== prevProps.bonusTarget &&
      this.state.bonusTarget === this.props.bonusTarget
    ) {
      // Reset the state if the bonus prop remains the same
      this.setState({ bonus: "" });
    }
    if (this.state.bonusTarget !== prevState.bonusTarget) {
    }
  }

  updateClass() {
    let inEffect = this.props.inEffect;
    let outEffect = this.props.outEffect;
    // Add the new class for zooming in

    this.bonusElement.classList.remove(outEffect);

    this.bonusElement.classList.add(inEffect);
  }

  revertClass() {
    let inEffect = this.props.inEffect;
    let outEffect = this.props.outEffect;
    // Remove the class for zooming in
    this.bonusElement.classList.remove(inEffect);

    // Add the class for zooming out
    this.bonusElement.classList.add(outEffect);
  }

  render() {
    // let classes = this.state.bonus % 100 === 0 ? "" : "displayNone";
    let bonusClass = this.props.bonusClass;
    return (
      <div
        ref={(element) => (this.bonusElement = element)}
        className={`animate__animated ${bonusClass}`}
      >
        {this.state.bonus}
      </div>
    );
  }
}
