// Modal.js
import React, { Component } from "react";

class Modal extends Component {
  render() {
    let width = this.props.width;
    let height = this.props.height;
    const { isOpen, onClose, children } = this.props;
    let closeButton =
      this.props.closeButton !== false ? (
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
      ) : (
        ""
      );
    if (!isOpen) return null;

    return (
      <div className="modal-overlay">
        <div
          style={{
            height: this.props.height,
            width: this.props.width,
            maxWidth: this.props.maxWidth,
            position: "relative",
            maxHeight: "90%",
            overflowY: "scroll",
          }}
          className="modal animate__animated animate__slideInDown animate__slower	3s"
        >
          {closeButton}
          {children}
        </div>
      </div>
    );
  }
}

export default Modal;
