import React, { Component } from "react";
import VerticalProgressBar from "./VerticalProgressBar";

export default class HiddenCreature extends Component {
  render() {
    return (
      <div className="hiddenCreatureContainer">
        <div className="payedFor">{`${this.props.payed}/${this.props.price}`}</div>
        <VerticalProgressBar
          maxValue={this.props.price}
          currentValue={this.props.payed}
        ></VerticalProgressBar>

        <img src={this.props.creature} className="hiddenCreature"></img>
      </div>
    );
  }
}
