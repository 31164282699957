import React, { Component } from "react";

export default class GameContainer extends Component {
  goToGame = () => {
    if (this.props.toggleModal) {
      this.props.toggleModal("noCoins");
    }
    window.location.assign(`${this.props.link}`);
  };
  render() {
    return (
      <div className="gameContainer ">
        <img
          onClick={() => this.goToGame()}
          className="golden-border clickable"
          src={this.props.picture}
        ></img>
      </div>
    );
  }
}
