import React, { Component } from "react";
import Vimeo from "@u-wave/react-vimeo";
import { playAudio } from "./utils";

class MyVideoComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showVideo: true,
    };
  }

  componentDidMount() {
    playAudio(this.props.sound, 1);
    setTimeout(() => {
      this.setState({ showVideo: false }, this.props.toggleModal("videoModal"));
    }, 6000); // Set the timeout to 3000 milliseconds (3 seconds)
  }

  render() {
    const { showVideo } = this.state;

    return (
      <div>
        {showVideo && <Vimeo video={this.props.video} autoplay responsive />}
      </div>
    );
  }
}

export default MyVideoComponent;
