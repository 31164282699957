import React, { Component } from "react";
import "../App.css";
import AllLetters from "../components.js/AllLetters";
import WordComponent from "../components.js/WordComponent";
import "animate.css";

import { avatars, diamondImg, playAudio } from "../components.js/utils";
import Bonus from "../components.js/Bonus";
import Timer from "../components.js/Timer";
import AvatarCard from "../components.js/AvatarCard";
import Modal from "../components.js/Modal";
import BestScoreCard from "../components.js/BestScoreCard";
import Gallery from "../components.js/Gallery";
// import "bootstrap/dist/css/bootstrap.min.css";

export default class LettersGame extends Component {
  componentDidUpdate() {}
  componentDidMount() {
    playAudio("garden-of-love-187530.mp3", 0.05);
  }
  render() {
    let elements = avatars.map((avatar, index) => {
      return (
        <img
          className="chooseAvatar"
          onClick={() => this.props.chooseAvatar(avatar)}
          key={index}
          src={avatar}
          alt={`Image ${index + 1}`}
          style={
            this.props.avatar === avatar
              ? { border: "2px solid #FFD700" }
              : { border: "" } // Adjust the border style as needed
          }
        />
      );
    });
    // let audio = new Audio();
    // this.setState({ audio: audio });
    return (
      <div>
        {" "}
        <div
        //  onMouseMove={() => }
        >
          <button onClick={this.props.toggleMute}>mute</button>
          <AllLetters
            currentLocation={this.props.currentLocation}
            selectLetter={this.props.selectLetter}
          ></AllLetters>
          {/* <div className="gameTimeContainer"> */}

          {/* </div>        <img className="rainbow" src={rainbow}></img> */}
          <Bonus
            bonusTarget={this.props.bonus}
            bonusClass="bonus"
            inEffect="animate__zoomInDown"
            outEffect="animate__zoomOutDown"
            updatepoints={this.props.updatepoints}
            type="diamonds"
            img={diamondImg}
            bonus={1}
            audio="success-fanfare-trumpets-6185.mp3"
          ></Bonus>
          <div className="centerContainer">
            <Timer
              initialTime={this.props.gameTime}
              spellWord={this.props.spellWord}
              isCounting={this.props.isGameCounting}
              allowUpdates={true}
              points={this.props.points}
              callback={this.props.finishGame}
            ></Timer>{" "}
            <div className="mainContainer">
              <AvatarCard
                updatepoints={this.props.updatepoints}
                initialTime={this.props.timeLeftForLetter}
                spellWord={this.props.spellWord}
                isCounting={this.props.isCounting}
                points={this.props.points}
                callback={this.props.finishGame}
                avatar={this.props.avatar}
                diamonds={this.props.diamonds}
              ></AvatarCard>
              <WordComponent
                currentLocation={this.props.currentLocation}
                selectedWord={this.props.selectedWord}
              ></WordComponent>
            </div>
          </div>

          <Modal
            closeButton={false}
            isOpen={this.props.modalOpen}
            onClose={() => this.props.closeModal("modalOpen")}
          >
            <h2>top scores</h2>

            <BestScoreCard
              bestScoreCoins={this.props.bestScoreCoins}
              currentScore={this.props.points}
            ></BestScoreCard>
            <button onClick={this.props.tryAgain}>try again</button>
          </Modal>
          <Modal
            closeButton={false}
            isOpen={this.props.startModal}
            onClose={() => this.props.closeModal("startModal")}
          >
            <h2>choose an avatar</h2>
            <Gallery
              // callback={this.props.chooseAvatar}
              elements={elements}
              // choosenElement={this.props.avatar}
            ></Gallery>
            <button onClick={this.props.startGame}>start</button>
          </Modal>
        </div>
      </div>
    );
  }
}
