import React, { Component } from "react";
import { addToLocalStorage, animals } from "./utils";
import Gallery from "./Gallery";
import Creature from "./Creature";
import HiddenCreature from "./HiddenCreature";
import VimeoFrame from "./VimeoFrame";
import Modal from "./Modal";

export default class CreaturesManu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.boughtTamagochies !== prevProps.boughtTamagochies) {
    }
  };

  //   toggleModal = () => {
  //     let isOpen = this.state.isOpen === false ? true : false;
  //     this.setState({ isOpen: isOpen });
  //   };
  render() {
    if (this.props.boughtTamagochies !== null) {
      let creatures = Object.entries(this.props.boughtTamagochies).map(
        (animal, i) => {
          //   if (animal[1]["already_payed"] === animal[1]["price"]) {
          //     let obj = this.props.boughtTamagochies;

          //     obj[animal[0]]["already_payed"] = 0;
          //     obj[animal[0]]["was_bought"] = true;
          //     addToLocalStorage("boughtTamagochies", obj);
          //   }
          if (animal[1].was_bought === true) {
            return (
              <>
                <Creature
                  resetGiveCreature={this.props.resetGiveCreature}
                  fun={this.props.fun}
                  food={this.props.food}
                  giveCreature={
                    this.props.giveCreature !== undefined
                      ? this.props.giveCreature[animal[1].image]
                      : ""
                  }
                  getAnimalPosition={this.props.getAnimalPosition}
                  //   detectTouchStartEnd={this.props.detectTouchStartEnd}
                  upadateTotalScore={this.props.upadateTotalScore}
                  boughtTamagochies={this.props.boughtTamagochies}
                  videoModal={this.props.videoModal}
                  toggleModal={this.props.toggleModal}
                  obj={animal}
                  creature={animal[1].image}
                  toggleModalRecieved={this.props.toggleModalRecieved}
                  sound={this.props.boughtTamagochies[animal[0]].sound}
                ></Creature>
              </>
            );
          } else {
            return (
              <HiddenCreature
                price={animal[1].price}
                creature={animal[1].image}
                payed={animal[1].already_payed}
              ></HiddenCreature>
            );
          }
        }
      );

      return (
        <Gallery
          // callback={this.props.chooseAvatar}
          elements={creatures}
          // choosenElement={this.props.avatar}
        ></Gallery>
      );

      // <div className="creatureManuContainer">{}</div>;
    }
  }
}
