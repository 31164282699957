import React, { Component } from "react";
import Timer from "./Timer";
import { coinImg, diamondImg } from "./utils";

export default class AvatarCard extends Component {
  render() {
    let timer =
      this.props.selectedWord === "" ? (
        ""
      ) : (
        <Timer
          updatepoints={this.props.updatepoints}
          initialTime={this.props.initialTime}
          spellWord={this.props.spellWord}
          isCounting={this.props.isCounting}
          allowUpdates={true}
        ></Timer>
      );
    return (
      <div className="avatarCard">
        {" "}
        <img className="mainAvatar" src={this.props.avatar}></img>
        {/* <Timer
          initialTime={this.props.gameTime}
          spellWord={this.props.spellWord}
          isCounting={this.props.isGameCounting}
          allowUpdates={true}
          points={this.props.points}
          callback={this.props.finishGame}
        ></Timer>{" "} */}
        <div className="timeContainer">
          <div>{timer}</div>
          {/* <span className="pointsContainer"> */}
          <div className="pointsContainer">
            <img
              //   style={{ position: "absolute", right: "176px" }}
              className="spinningCoin"
              src={coinImg}
            ></img>
            <div
            //   style={{ position: "absolute", right: " 151px", top: " -30px" }}
            >
              {this.props.points}
            </div>{" "}
          </div>
          <div className="pointsContainer">
            <img
              //   style={{ position: "absolute", right: "550px" }}
              className="spinningCoin"
              src={diamondImg}
            ></img>
            <div
            //   style={{ position: "absolute", right: " 500px", top: " -30px" }}
            >
              {this.props.diamonds}
            </div>{" "}
          </div>
          {/* </span> */}
        </div>
      </div>
    );
  }
}
