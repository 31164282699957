import React, { Component } from "react";
import Modal from "./Modal";
import VimeoFrame from "./VimeoFrame";
import HiddenCreature from "./HiddenCreature";
import BoughtCreature from "./BoughtCreature";

export default class Creature extends Component {
  constructor(props) {
    super(props);
    const name = this.extractName(props.creature);
    const savedData = JSON.parse(localStorage.getItem(name)) || {
      food: 100,
      sleep: 100,
      fun: 100,
      lastUpdate: new Date().getTime(), // Save the exact time of the last update
    };
    this.state = {
      name: name,
      food: savedData.food,
      sleep: savedData.sleep,
      fun: savedData.fun,
      lastUpdate: savedData.lastUpdate,
      was_bought: "",
      isOpen: false,
    };
    this.obj = this.props.obj;
  }

  componentDidMount() {
    this.interval = setInterval(this.updateStatus, 1000); // Update status every second
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.obj[1].was_bought === true &&
      this.props.obj[1].recievedModal === false
    ) {
      this.props.toggleModal("videoModal", this.props.obj[1]);
      this.props.toggleModalRecieved(this.props.obj[0]);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  updateStatus = () => {
    const now = new Date().getTime();
    const { food, sleep, fun, lastUpdate } = this.state;
    const timeDiff = (now - lastUpdate) / (1000 * 60 * 60); // Time difference in hours

    // Decrease status based on time passed
    const newFood = Math.max(0, food - timeDiff * (100 / 24)); // Decrease food by (100 / 24)% per hour
    const newSleep = Math.min(100, sleep + timeDiff * (100 / 24)); // Increase sleep by (100 / 24)% per hour
    const newFun = Math.max(0, fun - timeDiff * (100 / 24)); // Decrease fun by (100 / 24)% per hour

    this.setState(
      {
        food: newFood,
        sleep: newSleep,
        fun: newFun,
        lastUpdate: now,
      },
      () => {
        this.saveState();
      }
    );
  };

  saveState = () => {
    const { name, food, sleep, fun, lastUpdate } = this.state;
    const dataToSave = { name, food, sleep, fun, lastUpdate };
    localStorage.setItem(name, JSON.stringify(dataToSave));
  };

  extractName = (animal) => {
    if (typeof animal !== "string") {
      return "";
    }
    const name = animal.split(".")[0];
    return name;
  };

  feed = (type, sum) => {
    const { food, sleep } = this.state;
    const newFoodLevel = Math.min(food + sum, 100);
    const sleepDecrease = sum / 3.333; // Decrease sleep by a third of what is gained in food
    const newSleepLevel = Math.max(0, sleep - sleepDecrease);

    // Update state with the new levels
    this.setState(
      {
        [type]: newFoodLevel,
        sleep: newSleepLevel,
      },
      () => this.saveState()
    );
  };

  play = () => {
    const { fun, sleep } = this.state;
    const newFunLevel = Math.min(fun + 15, 100);
    const sleepDecrease = 15 / 3.333; // Decrease sleep by a third of what is gained in fun
    const newSleepLevel = Math.max(0, sleep - sleepDecrease);

    // Update state with the new levels
    this.setState(
      {
        fun: newFunLevel,
        sleep: newSleepLevel,
      },
      () => this.saveState()
    );
  };

  render() {
    const { name, food, sleep, fun } = this.state;

    return (
      <div>
        <BoughtCreature
          resetGiveCreature={this.props.resetGiveCreature}
          funPosition={this.props.fun}
          foodPosition={this.props.food}
          giveCreature={this.props.giveCreature}
          getAnimalPosition={this.props.getAnimalPosition}
          updateStatus={this.updateStatus}
          upadateTotalScore={this.props.upadateTotalScore}
          creature={this.props.creature}
          feed={this.feed}
          play={this.play}
          food={food}
          sleeptime={sleep}
          fun={fun}
          sound={this.props.sound}
        ></BoughtCreature>
      </div>
    );
  }
}
