import React, { Component } from "react";
import ProgressBar from "./ProgressBar";

class Timer extends Component {
  state = {
    timeLeft: this.props.initialTime || 0,
    highestValue: this.props.highestValue || 0,
  };

  setHighestValue = (value) => {
    this.setState((prevState) => {
      const newHighestValue = Math.max(prevState.highestValue, value);
      return {
        highestValue: newHighestValue,
      };
    });
  };

  timerTimeout;

  count = () => {
    if (this.props.isCounting) {
      clearTimeout(this.timerTimeout); // Clear existing timeout

      const updateInterval = 1000; // Update every second

      const updateTimer = () => {
        this.setState((prevState) => ({
          timeLeft: Math.max(prevState.timeLeft - 1, 0),
        }));

        this.timerTimeout = setTimeout(updateTimer, updateInterval);
      };

      updateTimer();
    }
  };

  componentDidMount() {
    this.count();
  }

  componentWillUnmount() {
    clearTimeout(this.timerTimeout);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.updatepoints &&
      this.props.spellWord !== prevProps.spellWord
    ) {
      this.props.updatepoints(
        this.state.timeLeft,
        "points",
        "collect-points-190037.mp3"
      );
    }
    if (this.props.initialTime > prevProps.initialTime) {
      this.setState({
        timeLeft: this.props.initialTime,
      });
    }
    if (
      this.props.isCounting &&
      this.props.isCounting !== prevProps.isCounting &&
      this.props.allowUpdates
    ) {
      this.count();
      this.setHighestValue(this.props.initialTime);
    }
    if (
      this.state.timeLeft !== prevState.timeLeft &&
      this.state.timeLeft === 0 &&
      this.props.callback
    ) {
      this.props.callback(this.props.points);
    }
  }

  formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds}`;
  };

  render() {
    return (
      <div>
        <div>
          <ProgressBar
            largest={this.state.highestValue}
            destination={0.1}
            number={this.state.timeLeft}
            // dangerPercentage={25}
          ></ProgressBar>
        </div>
        <div> {this.formatTime(this.state.timeLeft)}</div>
      </div>
    );
  }
}

export default Timer;
