import React, { Component } from "react";
import GameContainer from "./GameContainer";
import { gamesPictures } from "./utils";

export default class GamesMenu extends Component {
  render() {
    let games = gamesPictures.map((obj) => {
      return (
        <GameContainer
          toggleModal={this.props.toggleModal}
          picture={Object.keys(obj)[0]}
          link={`${Object.values(obj)[0]}`}
        ></GameContainer>
      );
    });
    return <div className="gamesContainer"> {games}</div>;
  }
}
