import React, { Component } from "react";
import { getImage, read } from "./utils";

export default class ChosenWord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: "",
    };
  }
  //   async componentDidMount() {
  //     let image = await getImage(this.props.selectedWord);
  //
  //     this.setState({ image: image });
  //   }
  async componentDidMount() {
    let image = await getImage(this.props.selectedWord);
    this.setState({ image: image });
  }
  async componentDidUpdate(prevProps, prevState) {
    if (this.props.selectedWord !== prevProps.selectedWord) {
      let image = await getImage(this.props.selectedWord);
      this.setState({ image: image });
    }
    if (this.state.image !== prevState.image) {
    }
    if (this.props.spellWord !== prevProps.spellWord) {
    }
  }
  render() {
    let word = this.props.selectedWord.split("");
    let editedword = word.map((letter, i) => {
      if (i + 1 <= this.props.currentLocation) {
        return (
          <span
            className="animate__animated animate__flipInX"
            style={{ color: "orangered" }}
          >
            {letter}
          </span>
        );
      } else {
        return <span>{letter}</span>;
      }
    });
    return (
      <div className="itemContainer">
        <div></div>
        <div
          onClick={() => {
            read(this.props.selectedWord);
          }}
          className="itemWord"
        >
          {" "}
          {editedword}
        </div>
        <img className="itemImage" src={this.state.image}></img>
      </div>
    );
  }
}
