import React, { Component } from "react";
import { coinImg, domain, firstPlace, secondPlace, thirdPlace } from "./utils";

export default class BestScoreCard extends Component {
  render() {
    let scores = this.props.bestScoreCoins.map((score, i) => {
      let avatar = score["avatar"] ? (
        <img
          className="avatar"
          src={score["avatar"]}
          alt={`${score["username"]} Avatar`}
        />
      ) : null;

      return (
        <div
          key={score["uniqueProperty"]}
          className="ScoreContainer golden-border"
        >
          <div className="scoreItem"> {avatar}</div>

          {i === 0 ? (
            <img
              className="scoreImg scoreItem"
              src={firstPlace}
              alt="1st Place"
            />
          ) : null}
          {i === 1 ? (
            <img
              className="scoreImg scoreItem"
              src={secondPlace}
              alt="2nd Place"
            />
          ) : null}
          {i === 2 ? (
            <img
              className="scoreImg scoreItem"
              src={thirdPlace}
              alt="3rd Place"
            />
          ) : null}
          {i === 3 ? (
            <div className="scoreImg scoreItem" alt="4th Place">
              {` ${"4th"} `}
            </div>
          ) : null}
          {i === 4 ? (
            <div className="scoreImg scoreItem" alt="5th Place">
              {` ${"5th"} `}{" "}
            </div>
          ) : null}
          <div className="scoreItem">{score["time"]}</div>
          <div className="scoreItem">{score["score"]}</div>
        </div>
      );
    });

    return (
      <div className="topScoresContainer">
        {scores}
        <button
          onClick={() => {
            window.location.assign(domain + "#/slot");
          }}
        >
          back to play
        </button>
      </div>
    );
  }
}
