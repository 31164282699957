import React, { Component } from "react";

export default class CountersBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let bulk = [];
    let allCounters = [];

    this.props.counters.forEach((counter, i) => {
      if (i % 2 === 0) {
        bulk = [];
        bulk.push(counter);
      } else {
        bulk.push(counter);
        allCounters.push(<div className="countersContainer">{bulk}</div>);
      }
    });

    return (
      <div className="countersContainer centered-item ">{allCounters}</div>
    );
  }
}
