import React, { Component } from "react";
import { getBoundingClientRect, playAudio } from "./utils";

class BoughtCreature extends Component {
  constructor(props) {
    super(props);

    this.state = {
      food: this.props.food,
      sleep: this.props.sleeptime,
      fun: this.props.fun,
      position: "",
    };
  }

  componentDidMount() {
    this.props.updateStatus(); // Call it once immediately to update the bars based on elapsed time since last update
    this.interval = setInterval(this.props.updateStatus, 1000);
    window.addEventListener("resize", this.setPosition);

    // Calculate position after the component has been rendered
    setTimeout(() => {
      this.setPosition();
    }, 3000);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.position !== prevState.position) {
      this.props.getAnimalPosition(this.props.creature, this.state.position);
    }
    if (
      this.props.funPosition !== prevProps.funPosition ||
      this.props.foodPosition !== prevProps.foodPosition
    ) {
      this.feedCreature(this.props.giveCreature).then(() => {
        this.props.resetGiveCreature();
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    window.removeEventListener("resize", this.setPosition);
  }

  setPosition = () => {
    const position = getBoundingClientRect(
      document.getElementById(this.props.creature)
    );
    this.setState({ position });
  };

  feedCreature = async (type) => {
    if (
      type === "food.png" ||
      type === "fun.png" ||
      type === "fun" ||
      type === "food"
    ) {
      type = type === "food.png" ? "food" : type;
      type = type === "fun.png" ? "fun" : type;
      type === "food"
        ? playAudio("chomp-155392.mp3", 1)
        : playAudio(this.props.sound, 1);

      this.props.feed(type, 10);
      this.props.upadateTotalScore(type, -10);
    }
  };

  handleDrop = (event) => {
    event.preventDefault();
    const droppedData = event.dataTransfer.getData("text/plain");
    const droppedDataKey = event.dataTransfer.getData("key");

    if (droppedData.includes("food.png") && droppedDataKey) {
      this.feedCreature("food");
    } else if (droppedData.includes("fun.png") && droppedDataKey) {
      this.feedCreature("fun");
    }
  };

  render() {
    const { creature } = this.props;
    const { food, sleeptime, fun } = this.props;

    return (
      <div
        draggable={true}
        onDrop={this.handleDrop}
        onDragOver={(e) => e.preventDefault()}
        onDragEndCapture={this.props.detectTouchStartEnd}
        className="boughtCratureContainer"
      >
        <img
          className="target-image"
          src={creature}
          alt="Creature"
          id={creature}
        ></img>
        <div className="boughtCreatureBars">
          <div>
            <p>🍗</p>
            <progress value={food} max="100"></progress>
          </div>
          <div>
            <p>😴</p>
            <progress value={sleeptime} max="100"></progress>
          </div>
          <div>
            <p>🙃</p>
            <progress value={fun} max="100"></progress>
          </div>
        </div>
      </div>
    );
  }
}

export default BoughtCreature;
