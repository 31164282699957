import React, { Component } from "react";
import Draggable from "react-draggable";
import { getBoundingClientRect, getElementPosition } from "./utils";

export default class Counter extends Component {
  handleDrop = (event) => {
    // Prevent default behavior to enable drop
    event.preventDefault();

    // Access the dataTransfer object to retrieve dropped data
    // const droppedData = event.dataTransfer.getData("text/plain");
    // const droppedDataKey = event.dataTransfer.getData("key");

    //

    // Check if the dropped data is the food element
    // if (droppedData.includes("food.png") && droppedDataKey) {
    //   // Increase food level by 10 (you can adjust this value as needed)
    //   this.feedCreature("food");
    //   playAudio("chomp-155392.mp3", 1);
    // } else if (droppedData.includes("fun.png") && droppedDataKey) {
    //   this.feedCreature("fun");
    //   playAudio(this.props.sound, 1);
    // }
  };
  render() {
    const { spin, count, imgSize, symbol, top, bottom, right, left } =
      this.props;

    let draggableItems = [];
    for (let i = 0; i < count; i++) {
      if (i == count - 1 && count > 9) {
        draggableItems.push(
          <Draggable
            key={i}
            onStop={(e) =>
              this.props.getAnimalPosition
                ? this.props.getAnimalPosition(
                    symbol,
                    getBoundingClientRect(e.target)
                  )
                : ""
            }
          >
            <img
              onDragStart={(event) => {
                event.dataTransfer.setData("key", i);
                event.dataTransfer.setData("className", event.target.className);
              }}
              key={i}
              style={{
                height: imgSize,
                position: "absolute",
                zIndex: 1000000000,
              }}
              className={`counterSymbol ${spin}`}
              src={symbol}
              alt={`Counter ${i}`}
            />
          </Draggable>
        );
      }
    }

    return (
      <div
        style={{
          top,
          bottom,
          right,
          left,
        }}
        // className="counterContainer"
      >
        {draggableItems}
        <img
          style={{ height: imgSize }}
          className={`counterSymbol ${spin}`}
          src={symbol}
          alt={`Counter ${count}`}
        />
        <span className="counterDigits">{count}</span>
      </div>
    );
  }
}
